import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import axios from 'axios';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import AsyncSelect from 'react-select/async';
import travelBg from '../../assets/images/travel/bg.jpg';
import airportData from '../../common/data/airports.json';

export default class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CheckIn: new Date(),
      CheckOut: new Date(),
      selectedFrom: '',
      selectedTo: '',
      statesFrom: [],
      statesTo: [],
      selectedStateFrom: '',
      selectedStateTo: '',
      email: '',
      phoneNumber: '',
      errorMessage: '',
      sender: false,
      pickup: false,
      modal: false,
      deliveryAddress: '',
      receiverFullname: '',
      receiverPhone: '',
      contentToDeliver: '',
      airports: airportData, // Initialize airports state with imported data
      transformedOptions: [],
    };
  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const isNumber = /^\d*$/.test(value);

    if (isNumber) {
      this.setState({ phoneNumber: value });
      if (value.length > 11) {
        this.setState({
          errorMessage: 'Phone number cannot be more than 11 digits.',
        });
      } else {
        this.setState({ errorMessage: '' });
      }
    } else {
      this.setState({ errorMessage: 'Please enter only numeric values.' });
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const {
      phoneNumber,
      destinationFrom,
      selectedStateFrom,
      destinationTo,
      selectedStateTo,
      email,
      CheckIn,
      CheckOut,
      sender,
      pickup,
      deliveryAddress,
      receiverFullname,
      receiverPhone,
      contentToDeliver,
    } = this.state;

    if (phoneNumber.length !== 11) {
      this.setState({
        errorMessage: 'Phone number must be exactly 11 digits.',
      });
    } else if (
      !destinationFrom ||
      !destinationTo ||
      !email ||
      !CheckIn ||
      !CheckOut
    ) {
      this.setState({ errorMessage: 'Please fill all the required fields.' });
    } else {
      this.setState({ errorMessage: '' });

      const formData = {
        destinationFrom,
        destinationTo,
        email,
        phoneNumber,
        CheckIn,
        CheckOut,
      };

      if (pickup) {
        axios
          .post('YOUR_PICKUP_SERVER_URL', formData)
          .then((response) => {
            console.log('Pickup form submitted successfully:', response.data);
            this.props.history.push('/userProfile'); // Navigate to user profile
          })
          .catch((error) => {
            console.error('Error submitting pickup form:', error);
          });
      }

      if (sender) {
        if (
          deliveryAddress &&
          receiverFullname &&
          receiverPhone &&
          contentToDeliver
        ) {
          const senderData = {
            deliveryAddress,
            receiverFullname,
            receiverPhone,
            contentToDeliver,
            ...formData, // Include other form data
          };
          axios
            .post('YOUR_SENDER_SERVER_URL', senderData)
            .then((response) => {
              console.log('Sender form submitted successfully:', response.data);
              this.toggleModal();
              this.props.history.push('/userProfile'); // Navigate to user profile
            })
            .catch((error) => {
              console.error('Error submitting sender form:', error);
            });
        } else {
          this.setState({ errorMessage: 'Please fill all sender details.' });
        }
      }
    }
  };

  handleChangeFrom = (e) => {
    this.setState({ selectFrom: e.value });
  };

  handleChangeTo = (e) => {
    this.setState({ selectTo: e.value });
  };

  handleCheckboxChange = (e) => {
    const {
      destinationFrom,
      destinationTo,
      email,
      phoneNumber,
      CheckIn,
      CheckOut,
    } = this.state;
    if (
      !destinationFrom ||
      !destinationTo ||
      !email ||
      !phoneNumber ||
      !CheckIn ||
      !CheckOut
    ) {
      this.setState({
        errorMessage:
          'Please fill all the required fields before selecting an option.',
      });
    } else {
      const { name, checked } = e.target;
      if (checked) {
        if (name === 'sender') {
          this.setState({ sender: true, pickup: false }, () => {
            this.toggleModal();
          });
        } else if (name === 'pickup') {
          this.setState({ pickup: true, sender: false });
        }
      } else {
        this.setState({ [name]: false });
      }
    }
  };

  render() {
    const {
      CheckIn,
      CheckOut,
      email,
      phoneNumber,
      errorMessage,
      modal,
      deliveryAddress,
      receiverFullname,
      receiverPhone,
      contentToDeliver,
    } = this.state;

    const filterAirports = (inputValue) => {
      return transformedOptions.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase()),
      );
    };

    const loadOptions = (inputValue, callback) => {
      setTimeout(() => {
        callback(filterAirports(inputValue));
      }, 1000);
    };

    const transformedOptions = airportData.map((airport) => ({
      value: airport.iata_code,
      label: `${airport.name} (${airport.iata_code})`,
    }));

    return (
      <React.Fragment>
        <section
          className="bg-half-170 d-table w-100"
          style={{ background: `url(${travelBg}) center center` }}
        >
          <div className="bg-overlay"></div>
          <Container>
            <Row className="align-items-center">
              <Col lg={7} md={6}>
                <div className="title-heading mt-4">
                  <h1 className="display-4 fw-bold text-white title-dark mb-3">
                    Sending items <br /> is now easier
                  </h1>
                  <p className="para-desc text-white-50">
                    Register your items, we pick them up and deliver to your
                    destination across the world.
                  </p>
                  <div className="mt-4">
                    <Link
                      to="#"
                      className="btn btn-icon btn-pills btn-lg btn-light"
                    >
                      <FeatherIcon icon="arrow-down" className="icons" />
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg={5} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <Card className="shadow rounded border-0 ms-lg-5">
                  <CardBody>
                    <h5 className="card-title">You can start search here</h5>

                    <Form
                      className="login-form"
                      onSubmit={this.handleFormSubmit}
                    >
                      <Row>
                        <Col md={12}>
                          {' '}
                          <div className="mb-3">
                            <Label className="form-label">Destination</Label>
                            <div className="form-icon position-relative mb-3">
                              <i>
                                <FeatherIcon
                                  icon="map-pin"
                                  className="fea icon-sm icons"
                                />
                              </i>
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                onChange={this.handleChangeFrom}
                                placeholder={'Select Departure Airport'}
                                loadOptions={loadOptions}
                              />
                            </div>

                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="map-pin"
                                  className="fea icon-sm icons"
                                />
                              </i>
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                onChange={this.handleChangeTo}
                                placeholder={'Select Destination Airport'}
                                loadOptions={loadOptions}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                              <Input
                                type="email"
                                className="form-control ps-5"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="phone"
                                  className="fea icon-sm icons"
                                />
                              </i>
                              <Input
                                type="tel"
                                className="form-control ps-5"
                                name="phoneNumber"
                                value={phoneNumber}
                                onChange={this.handlePhoneNumberChange}
                                maxLength="11"
                                required
                              />
                            </div>
                            {errorMessage && (
                              <div className="text-danger mt-2">
                                {errorMessage}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Departure Date</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="calendar"
                                  className="fea icon-sm icons"
                                />
                              </i>
                              <Flatpickr
                                value={CheckIn}
                                onChange={(date) =>
                                  this.setState({ CheckIn: date })
                                }
                                className="form-control ps-5"
                                placeholder="Select date"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Arrival Date</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="calendar"
                                  className="fea icon-sm icons"
                                />
                              </i>
                              <Flatpickr
                                value={CheckOut}
                                onChange={(date) =>
                                  this.setState({ CheckOut: date })
                                }
                                className="form-control ps-5"
                                placeholder="Select date"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="senderCheck"
                                name="sender"
                                onChange={this.handleCheckboxChange}
                                checked={this.state.sender}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="senderCheck"
                              >
                                Sender
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="pickupCheck"
                                name="pickup"
                                onChange={this.handleCheckboxChange}
                                checked={this.state.pickup}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="pickupCheck"
                              >
                                PickUp
                              </label>
                            </div>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="d-grid">
                            <button className="btn btn-primary">Submit</button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        {/* Modal for Sender */}
        <Modal isOpen={modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Sender Information
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className="mb-3">
                <Label className="form-label">Delivery Address</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="deliveryAddress"
                  value={deliveryAddress}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Receiver Full Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="receiverFullname"
                  value={receiverFullname}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Receiver Phone</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="receiverPhone"
                  value={receiverPhone}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Content to Deliver</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="contentToDeliver"
                  value={contentToDeliver}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
