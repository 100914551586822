import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  Container,
  Form,
  Modal,
  ModalBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import images
import logodark from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';
import shop1 from '../../assets/images/shop/product/s-1.jpg';
import shop2 from '../../assets/images/shop/product/s-2.jpg';
import shop3 from '../../assets/images/shop/product/s-3.jpg';

import NavbarButtons from '../Shared/NavbarButtons';
import appStore from '../../assets/images/app/app-store.png';
import playStore from '../../assets/images/app/play-store.png';
import RightSidebar from './RightSidebar';
import withRouter from '../../common/data/withRouter';

// menu
// import { navLinks } from './menu';
// import { MenuItem } from './NavBarComponents';

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpenShop: false,
      wishlistModal: false,
      dropdownIsOpen: false,
      open: false,
      position: 'end',
      dropdownOpen: false,
      landing: false,
      components: false,
      demo: false,
      doc: false,
      pages: false,
      company: false,
      account: false,
      email: false,
      blog: false,
      case: false,
      auth: false,
      login: false,
      signup: false,
      reset: false,
      utility: false,
      special: false,
      contact: false,
      multi: false,
      level2: false,
      isOffcanvasOpen: false,
    };
    this.toggleLine = this.toggleLine.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.toggleDropdown.bind(this);
    this.togglemodal.bind(this);
    this.removeActivation = this.removeActivation.bind(this);
  }

  /**
   * Right sidebar drawer
   **/

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  closeOffcanvas = () => {
    this.setState({ isOffcanvasOpen: false });
  };

  toggleRightDrawer = () => {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  };
  onDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  // Topbar Page Activation

  activateParentDropdown() {
    var menuItems = document.getElementsByClassName('sub-menu-item');

    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].pathname === window.location.pathname) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');

        const immediateParent = matchingMenuItem.closest('li');
        if (immediateParent) {
          immediateParent.classList.add('active');
        }
        var parent = matchingMenuItem.closest('.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          var parentMenuitem = parent.querySelector('a');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }
          var parentOfParent = parent.closest('.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          parentOfParent = matchingMenuItem.closest('.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
      return false;
    }
    return false;
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
    this.initializeNavigation();
  }

  initializeNavigation = () => {
    const pathName = window.location.pathname;
    var matchingMenuItem = null;
    var ul = document.getElementById('navigation');
    var items = ul.getElementsByTagName('a');
    this.removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  };

  removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        if (parent.classList.contains('active')) {
          parent.classList.remove('active');
        }
      }
    }
  };

  render() {
    const { isOffcanvasOpen } = this.state;

    return (
      <React.Fragment>
        {this.props.tagline ? this.props.tagline : null}

        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              {/* logo */}
              {this.props.hasDarkTopBar ? (
                <Link className="logo" to="/">
                  <img
                    src={logodark}
                    height="24"
                    className="logo-light-mode"
                    alt=""
                  />
                  <img
                    src={logolight}
                    height="24"
                    className="logo-dark-mode"
                    alt=""
                  />
                </Link>
              ) : (
                <Link className="logo" to="/">
                  <span className="logo-light-mode">
                    <img src={logodark} className="l-dark" height="24" alt="" />
                    <img
                      src={logolight}
                      className="l-light"
                      height="24"
                      alt=""
                    />
                  </span>
                  <img
                    src={logolight}
                    height="24"
                    className="logo-dark-mode"
                    alt=""
                  />
                </Link>
              )}
            </div>

            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? 'navbar-toggle open' : 'navbar-toggle'
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            {(() => {
              if (this.props.router.location.pathname === '/index-developer') {
                return <NavbarButtons />;
              } else if (
                this.props.router.location.pathname === '/index-it-solution-two'
              ) {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleDropdown}
                      >
                        <DropdownToggle
                          color="none"
                          type="button"
                          className="btn-link text-decoration-none dropdown-toggle p-0 pe-2"
                        >
                          <i className="uil uil-search text-muted"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          end
                          className="dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
                          style={{ width: '300px' }}
                        >
                          <div className="search-bar">
                            <Form className="searchform">
                              <input
                                type="text"
                                id="text"
                                name="name"
                                className="form-control border rounded"
                                placeholder="Search..."
                              />
                            </Form>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </li>{' '}
                    <li className="list-inline-item mb-0">
                      <Link to="#" onClick={this.toggleOffcanvas}>
                        <div className="btn btn-icon btn-pills btn-primary settingbtn">
                          <FeatherIcon
                            icon="settings"
                            className="fea icon-sm"
                          />
                        </div>
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item ps-1 mb-0">
                      <Link
                        to="//1.envato.market/landrickreactjs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          id="buyButton"
                          className="btn btn-icon btn-pills btn-primary shoppingbtn"
                        >
                          <FeatherIcon
                            icon="shopping-cart"
                            className="fea icon-sm"
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                );
              } else if (
                this.props.router.location.pathname === '/shop-grids' ||
                this.props.router.location.pathname === '/shop-lists'
              ) {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleDropdown}
                      >
                        <DropdownToggle
                          direction="right"
                          color="none"
                          type="button"
                          className="btn btn-link text-decoration-none p-0 pe-2"
                        >
                          <i className="mdi mdi-magnify h4 text-muted"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          end
                          className="dd-menu bg-white shadow rounded border-0 mt-3 py-0"
                          style={{ width: '300px' }}
                        >
                          <Form>
                            <input
                              type="text"
                              id="text"
                              name="name"
                              className="form-control border bg-white"
                              placeholder="Search..."
                            />
                          </Form>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                    <li className="list-inline-item mb-0 pe-1">
                      <Dropdown
                        isOpen={this.state.dropdownOpenShop}
                        toggle={this.toggleDropdownShop}
                      >
                        <DropdownToggle
                          type="button"
                          className="btn btn-icon btn-soft-primary "
                        >
                          <i className="uil uil-shopping-cart align-middle icons"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          direction="left"
                          className="dd-menu bg-white shadow rounded border-0 mt-3 p-4"
                          style={{ width: '300px' }}
                        >
                          <div className="pb-4">
                            <Link to="#" className="media align-items-center">
                              <img
                                src={shop1}
                                className="shadow rounded"
                                style={{ maxWidth: '64px' }}
                                alt=""
                              />
                              <div className="flex-1 text-start ms-3">
                                <h6 className="text-dark mb-0">T-shirt (M)</h6>
                                <p className="text-muted mb-0">$320 X 2</p>
                              </div>
                              <h6 className="text-dark mb-0">$640</h6>
                            </Link>

                            <Link
                              to="#"
                              className="media align-items-center mt-4"
                            >
                              <img
                                src={shop2}
                                className="shadow rounded"
                                alt=""
                                style={{ maxWidth: '64px' }}
                              />
                              <div className="flex-1 text-start ms-3">
                                <h6 className="text-dark mb-0">Bag</h6>
                                <p className="text-muted mb-0">$50 X 5</p>
                              </div>
                              <h6 className="text-dark mb-0">$250</h6>
                            </Link>

                            <Link
                              to="#"
                              className="media align-items-center mt-4"
                            >
                              <img
                                src={shop3}
                                className="shadow rounded"
                                style={{ maxWidth: '64px' }}
                                alt=""
                              />
                              <div className="flex-1 text-start ms-3">
                                <h6 className="text-dark mb-0">Watch (Men)</h6>
                                <p className="text-muted mb-0">$800 X 1</p>
                              </div>
                              <h6 className="text-dark mb-0">$800</h6>
                            </Link>
                          </div>

                          <div className="media align-items-center justify-content-between pt-4 border-top">
                            <h6 className="text-dark mb-0">Total($):</h6>
                            <h6 className="text-dark mb-0">$1690</h6>
                          </div>

                          <div className="mt-3 text-center">
                            <Link to="#" className="btn btn-primary me-2">
                              View Cart
                            </Link>
                            <Link to="#" className="btn btn-primary">
                              Checkout
                            </Link>
                          </div>
                          <p className="text-muted text-start mt-1 mb-0">
                            *T&C Apply
                          </p>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                    <li className="list-inline-item mb-0 pe-1">
                      <Link
                        to="#"
                        className="btn btn-icon btn-soft-primary"
                        onClick={this.toggleWishlistModal}
                      >
                        <i className="uil uil-heart align-middle icons"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item mb-0">
                      <Dropdown
                        color="primary"
                        isOpen={this.state.dropdownIsOpen}
                        toggle={this.toggleDropdownIsOpen}
                      >
                        <DropdownToggle
                          type="button"
                          className="btn btn-icon btn-soft-primary"
                        >
                          <i className="uil uil-user align-middle icons"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          direction="left"
                          className="dd-menu bg-white shadow rounded border-0 mt-3 py-3"
                          style={{ width: '200px' }}
                        >
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-user align-middle me-1"></i>{' '}
                            Account
                          </Link>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-clipboard-notes align-middle me-1"></i>{' '}
                            Order History
                          </Link>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-arrow-circle-down align-middle me-1"></i>{' '}
                            Download
                          </Link>
                          <div className="dropdown-divider my-3 border-top"></div>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-sign-out-alt align-middle me-1"></i>{' '}
                            Logout
                          </Link>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                  </ul>
                );
              } else if (
                this.props.router.location.pathname === '/index-apps' ||
                this.props.router.location.pathname === '/index-classic-app' ||
                this.props.router.location.pathname === '/index-job'
              ) {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Link to="#" className="btn btn-icon btn-light">
                        <img
                          src={appStore}
                          className="avatar avatar-ex-small p-1"
                          alt=""
                        />
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item mb-0">
                      <Link to="#" className="btn btn-icon btn-light">
                        <img
                          src={playStore}
                          className="avatar avatar-ex-small p-1"
                          alt=""
                        />
                      </Link>
                    </li>
                  </ul>
                );
              } else {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Link
                        to="/auth-login"
                        onClick={this.toggleOffcanvas}
                        disabled={this.state.open}
                      >
                        <div className="btn btn-primary">
                          {/* <FeatherIcon
                            icon="settings"
                            className="fea icon-sm"
                          /> */}
                          Login
                        </div>
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item ps-1 mb-0">
                      <Link
                        to="/page-messages"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="btn btn-icon btn-pills shoppingbtn">
                          <FeatherIcon icon="user" className="fea icon-md" />
                        </div>
                      </Link>
                    </li>
                  </ul>
                );
              }
            })()}

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? 'block' : 'none' }}
            >
              {/* Menu Dropdown 
              <ul className="navigation-menu nav-dark" id="top-menu">
                * home *
                <li>
                  <Link to="/index" className="sub-menu-item">
                    Home
                  </Link>
                </li>
                <li className="has-submenu parent-parent-menu-item">
                   Landing 
                  <Link
                    to="#"
                    onClick={() =>
                      this.setState({ landing: !this.state.landing })
                    }
                  >
                    Landing
                  </Link>
                  <span className="menu-arrow"></span>
                  <ul
                    className={classnames('submenu megamenu', {
                      open: this.state.landing,
                    })}
                  >
                    <li>
                      <ul>
                        <li className="megamenu-head">
                          <i className="uil uil-book-open fs-6 align-middle"></i>{' '}
                          Landing Pages
                        </li>
                        <li>
                          <Link to="/index-saas" className="sub-menu-item">
                            Saas
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-classic-saas"
                            className="sub-menu-item"
                          >
                            Classic Saas
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-agency" className="sub-menu-item">
                            Agency
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-apps" className="sub-menu-item">
                            Application
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-classic-app"
                            className="sub-menu-item"
                          >
                            Classic Application
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-studio" className="sub-menu-item">
                            Studio
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-marketing" className="sub-menu-item">
                            Marketing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-enterprise"
                            className="sub-menu-item"
                          >
                            Enterprise
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-services" className="sub-menu-item">
                            Service
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-payments" className="sub-menu-item">
                            Payments
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-it-solution"
                            className="sub-menu-item"
                          >
                            IT Solution{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-it-solution-two"
                            className="sub-menu-item"
                          >
                            IT Solution Two{' '}
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <ul>
                        <li className="megamenu-head">
                          <i className="uil uil-book-open fs-6 align-middle"></i>{' '}
                          Landing Pages
                        </li>
                        <li>
                          <Link to="/index-developer" className="sub-menu-item">
                            Developer
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-seo-agency"
                            className="sub-menu-item"
                          >
                            SEO Agency
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-hospital" className="sub-menu-item">
                            Hospital
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-coworking" className="sub-menu-item">
                            Coworking
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-business" className="sub-menu-item">
                            Business
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-modern-business"
                            className="sub-menu-item"
                          >
                            Modern Business
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-finance" className="sub-menu-item">
                            Finance{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-logistics" className="sub-menu-item">
                            Delivery & Logistics{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-social-marketing"
                            className="sub-menu-item"
                          >
                            Social Media
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-digital-agency"
                            className="sub-menu-item"
                          >
                            Digital Agency
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-customer" className="sub-menu-item">
                            Customer
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-software" className="sub-menu-item">
                            Software
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <ul>
                        <li className="megamenu-head">
                          <i className="uil uil-book-open fs-6 align-middle"></i>{' '}
                          Landing Pages
                        </li>
                        <li>
                          <Link to="/index-hotel" className="sub-menu-item">
                            Hotel
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-construction"
                            className="sub-menu-item"
                          >
                            Construction
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-real-estate"
                            className="sub-menu-item"
                          >
                            Real Estate
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-videocall" className="sub-menu-item">
                            Video Conference{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-blockchain"
                            className="sub-menu-item"
                          >
                            Blockchain{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-crypto-two"
                            className="sub-menu-item"
                          >
                            Cryptocurrency Two{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-integration"
                            className="sub-menu-item"
                          >
                            Integration
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-task-management"
                            className="sub-menu-item"
                          >
                            Task Management{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-email-inbox"
                            className="sub-menu-item"
                          >
                            Email Inbox{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-travel" className="sub-menu-item">
                            Travel{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-course" className="sub-menu-item">
                            Course
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-online-learning"
                            className="sub-menu-item"
                          >
                            Online Learning
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <ul>
                        <li className="megamenu-head">
                          <i className="uil uil-book-open fs-6 align-middle"></i>{' '}
                          Landing Pages
                        </li>
                        <li>
                          <Link to="/index-insurance" className="sub-menu-item">
                            Insurance
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-single-product"
                            className="sub-menu-item"
                          >
                            Product
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-car-riding"
                            className="sub-menu-item"
                          >
                            Car Ride
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-landing-one"
                            className="sub-menu-item"
                          >
                            Landing One{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-landing-two"
                            className="sub-menu-item"
                          >
                            Landing Two{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-landing-three"
                            className="sub-menu-item"
                          >
                            Landing Three{' '}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-landing-four"
                            className="sub-menu-item"
                          >
                            Landing Four
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-personal" className="sub-menu-item">
                            Personal
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/index-freelancer"
                            className="sub-menu-item"
                          >
                            Freelance{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-event" className="sub-menu-item">
                            Event
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-ebook" className="sub-menu-item">
                            E-Book
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-onepage" className="sub-menu-item">
                            Saas{' '}
                            <span className="badge bg-warning ms-2">
                              Onepage
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <ul>
                        <li className="megamenu-head">
                          <i className="uil uil-cube fs-6 align-middle"></i>{' '}
                          Full Demos
                        </li>
                        <li>
                          <Link to="/index-corporate" className="sub-menu-item">
                            Corporate
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-crypto" className="sub-menu-item">
                            Cryptocurrency
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-shop" className="sub-menu-item">
                            Shop
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-portfolio" className="sub-menu-item">
                            Portfolio
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/helpcenter-overview"
                            className="sub-menu-item"
                          >
                            Help Center
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-hosting" className="sub-menu-item">
                            Hosting & Domain
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-job" className="sub-menu-item">
                            Jobs & Careers
                          </Link>
                        </li>
                        <li>
                          <Link to="/forums" className="sub-menu-item">
                            Forums
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-blog" className="sub-menu-item">
                            Blog or News
                          </Link>
                        </li>
                        <li>
                          <Link to="/index-nft" className="sub-menu-item">
                            NFT Marketplace
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>*/}

              {/* <ul className="navigation-menu" id="top-menu">
                {(navLinks || []).map((item, key) => {
                  const hasChildren = item.child && item.child.length;
                  return (
                    <MenuItem
                      item={item}
                      key={key}
                      itemClassName={hasChildren ? 'parent-parent-menu-item' : ''}
                      arrowClassName="menu-arrow"
                    />
                  );
                })}
              </ul> */}
              {/* menu end */}
              <div className="buy-menu-btn d-none">
                <Link
                  to="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Get started
                </Link>
              </div>
            </div>
          </Container>
        </header>

        <Modal
          isOpen={this.state.wishlistModal}
          tabIndex="-1"
          centered
          contentClassName="rounded shadow-lg border-0 overflow-hidden"
          toggle={this.toggleWishlistModal}
        >
          <ModalBody className="py-5">
            <div className="text-center">
              <div
                className="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                style={{ height: '95px', width: '95px' }}
              >
                <h1 className="mb-0">
                  <i className="uil uil-heart-break align-middle"></i>
                </h1>
              </div>
              <div className="mt-4">
                <h4>Your wishlist is empty.</h4>
                <p className="text-muted">
                  Create your first wishlist request...
                </p>
                <div className="mt-4">
                  <Link to="#" className="btn btn-outline-primary">
                    + Create new wishlist
                  </Link>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Side Offcanvas */}
        <Offcanvas
          isOpen={isOffcanvasOpen}
          direction={this.state.position}
          toggle={this.toggleOffcanvas}
        >
          <OffcanvasBody>
            <RightSidebar onClose={this.closeOffcanvas} />
          </OffcanvasBody>
        </Offcanvas>
      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);
