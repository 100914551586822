import React from 'react';
import { Navigate } from 'react-router-dom';

//Special
import PageComingSoon from '../pages/Pages/Special/PageComingSoon';

import PageComingSoon2 from '../pages/Pages/Special/PageComingSoon2';

import PageError from '../pages/Pages/Special/PageError';

import PageThankYou from '../pages/Pages/Special/PageThankYou';

import PageMaintenance from '../pages/Pages/Special/PageMaintenance';

//Auth Pages
import PageLogin from '../pages/Pages/AuthPages/PageLogin';

import PageCoverLogin from '../pages/Pages/AuthPages/PageCoverLogin';

import PageLoginThree from '../pages/Pages/AuthPages/PageLoginThree';

import PageLoginFive from '../pages/Pages/AuthPages/PageLoginFive';

import PageSignup from '../pages/Pages/AuthPages/PageSignup';

import PageCoverSignup from '../pages/Pages/AuthPages/PageCoverSignup';

import PageSignupThree from '../pages/Pages/AuthPages/PageSignupThree';

import PageSignupFive from '../pages/Pages/AuthPages/PageSignupFive';

import PageCoverRePassword from '../pages/Pages/AuthPages/PageCoverRePassword';

import PageRecoveryPassword from '../pages/Pages/AuthPages/PageRecoveryPassword';

import PageRePasswordThree from '../pages/Pages/AuthPages/PageRePasswordThree';

import PageResetPassFive from '../pages/Pages/AuthPages/PageResetPassFive';

import Travel from '../pages/Travel/index';

import PageMembers from '../pages/Pages/Account/PageMembers';

import PageWorks from '../pages/Pages/Account/PageWorks';
import PageMessages from '../pages/Pages/Account/PageMessages';

//Account
import PageProfile from '../pages/Pages/Account/PageProfile';
import PageProfileEdit from '../pages/Pages/Account/PageProfileEdit';
import PagePayments from '../pages/Pages/Account/PagePayments';
import PageInvoice from '../pages/Pages/Account/PageInvoice';
import PageChat from '../pages/Pages/Account/PageChat';

//Contact
import PageContactDetail from '../pages/Pages/Contact/PageContactDetail';

//Email
import EmailAlert from '../pages/Pages/EmailTemplate/EmailAlert';
import EmailPasswordReset from '../pages/Pages/EmailTemplate/EmailPasswordReset';
import EmailConfirmation from '../pages/Pages/EmailTemplate/EmailConfirmation';
import EmailInvoice from '../pages/Pages/EmailTemplate/EmailInvoice';

//Help Center
import HelpCenterOverview from '../pages/Pages/HelpCenter/HelpCenterOverview';
import HelpCenterFaqs from '../pages/Pages/HelpCenter/HelpCenterFaqs';
import HelpCenterGuides from '../pages/Pages/HelpCenter/HelpCenterGuides';
import HelpCenterSupportRequest from '../pages/Pages/HelpCenter/HelpCenterSupportRequest';

import AuthLogin from '../pages/Pages/DemoJob/AuthLogin';
import AuthSignup from '../pages/Pages/DemoJob/AuthSignup';
import AuthReset from '../pages/Pages/DemoJob/AuthReset';

const routes = [
  //Help Center
  {
    path: '/helpcenter-overview',
    component: <HelpCenterOverview />,
    isWithoutLayout: true,
  },
  {
    path: '/helpcenter-faqs',
    component: <HelpCenterFaqs />,
    isWithoutLayout: true,
  },
  {
    path: '/helpcenter-guides',
    component: <HelpCenterGuides />,
    isWithoutLayout: true,
  },
  {
    path: '/helpcenter-support-request',
    component: <HelpCenterSupportRequest />,
    isWithoutLayout: true,
  },

  //Contact without layout
  {
    path: '/page-contact-detail',
    component: <PageContactDetail />,
    isWithoutLayout: true,
    isTopbarDark: true,
  },

  //Email Pages
  { path: '/email-alert', component: <EmailAlert />, isWithoutLayout: true },
  {
    path: '/email-password-reset',
    component: <EmailPasswordReset />,
    isWithoutLayout: true,
  },
  {
    path: '/email-confirmation',
    component: <EmailConfirmation />,
    isWithoutLayout: true,
  },
  {
    path: '/email-invoice',
    component: <EmailInvoice />,
    isWithoutLayout: true,
  },

  //Special Pages
  {
    path: '/page-comingsoon',
    component: <PageComingSoon />,
    isWithoutLayout: true,
  },
  {
    path: '/page-comingsoon2',
    component: <PageComingSoon2 />,
    isWithoutLayout: true,
  },
  { path: '/page-error', component: <PageError />, isWithoutLayout: true },
  {
    path: '/page-thankyou',
    component: <PageThankYou />,
    isWithoutLayout: true,
  },
  {
    path: '/page-maintenance',
    component: <PageMaintenance />,
    isWithoutLayout: true,
  },

  { path: '/auth-bs-login', component: <AuthLogin />, isWithoutLayout: true },
  { path: '/auth-bs-signup', component: <AuthSignup />, isWithoutLayout: true },
  { path: '/auth-bs-reset', component: <AuthReset />, isWithoutLayout: true },
  //User Pages
  { path: '/auth-login', component: <PageLogin />, isWithoutLayout: true },
  {
    path: '/auth-cover-login',
    component: <PageCoverLogin />,
    isWithoutLayout: true,
  },
  {
    path: '/auth-login-three',
    component: <PageLoginThree />,
    isWithoutLayout: true,
  },

  {
    path: '/auth-login-bg-video',
    component: <PageLoginFive />,
    isWithoutLayout: true,
  },

  { path: '/auth-signup', component: <PageSignup />, isWithoutLayout: true },
  {
    path: '/auth-cover-signup',
    component: <PageCoverSignup />,
    isWithoutLayout: true,
  },
  {
    path: '/auth-signup-three',
    component: <PageSignupThree />,
    isWithoutLayout: true,
  },

  {
    path: '/auth-signup-bg-video',
    component: <PageSignupFive />,
    isWithoutLayout: true,
  },

  {
    path: '/auth-re-password',
    component: <PageRecoveryPassword />,
    isWithoutLayout: true,
  },
  {
    path: '/auth-cover-re-password',
    component: <PageCoverRePassword />,
    isWithoutLayout: true,
  },
  {
    path: '/auth-re-password-three',
    component: <PageRePasswordThree />,
    isWithoutLayout: true,
  },

  {
    path: '/auth-reset-password-bg-video',
    component: <PageResetPassFive />,
    isWithoutLayout: true,
  },

  //Page Profile
  { path: '/page-profile', component: <PageProfile /> },
  { path: '/page-members', component: <PageMembers /> },
  { path: '/page-works', component: <PageWorks /> },
  { path: '/page-profile-edit', component: <PageProfileEdit /> },
  { path: '/page-payments', component: <PagePayments /> },
  { path: '/page-invoice', component: <PageInvoice />, isTopbarDark: true},
  { path: '/page-chat', component: <PageChat /> },
  
  //Index Main
  {
    path: '/',
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: '/index', component: <Travel />, isTopbarDark: true },
  {
    path: '/',
    exact: true,
    component: <Navigate to="/page-messages" />,
  },
  { path: '/page-messages', component: <PageMessages />,   isTopbarDark: true },
  {
    path: '/',
    exact: true,
    component: <Navigate to="/auth-signup" />,
  },
  { path: '/auth-signup', component: <PageSignup />, isWithoutLayout: true },
  {
    path: '/',
    exact: true,
    component: <Navigate to="/auth-login" />,
  },
  { path: '/auth-login', component: <PageLogin />, isWithoutLayout: true },

];

export default routes;
